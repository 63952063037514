import * as React from "react"

import styles from "./button.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

export const Button = ({ color, onClick, children }) => (
  <button
    onClick={onClick}
    className={cx("button", {
      "button--blue": color === "blue",
      "button--green": color === "green",
    })}
  >
    {children}
  </button>
)
