import gifshot from "gifshot"

export const imgToURL = (img, { width, height }) => {
  const canvas = document.createElement("canvas")
  canvas.width = width * devicePixelRatio
  canvas.height = height * devicePixelRatio
  const ctx = canvas.getContext("2d")
  ctx.scale(devicePixelRatio, devicePixelRatio)
  ctx.drawImage(img, 0, 0)
  const pngURL = canvas.toDataURL("image/png")
  return pngURL
}

export const loadImage = async (src) => {
  const img = new Image()
  return new Promise((resolve) => {
    img.crossOrigin = ""
    img.onload = () => resolve(img)
    img.src = src
  })
}

// TODO: why doesn't gif support transparency?
// Possible workaround:
// - Use get-pixels to get pixel data from frames (https://github.com/scijs/get-pixels)
// - Use image-palette to generate palettes from images (https://github.com/dy/image-palette)
// - use omggif directly to write gifs
// Another possible workaround:
// - Use AnimatedGif directly (https://github.com/sole/Animated_GIF)
export const imgsToGif = async (imgs, { width, height }) =>
  new Promise((resolve) =>
    gifshot.createGIF(
      {
        images: imgs,
        gifWidth: width * devicePixelRatio,
        gifHeight: height * devicePixelRatio,
      },
      ({ image }) => {
        resolve(image)
      }
    )
  )
