import { Filter } from "@mattsnax/filter-effects"
import * as f from "@mattsnax/filter-effects"
import { solidColor } from "./pattern"

// Convolution Effects
export const convolve = (matrix) => (source) => {
  const rank = Math.sqrt(matrix.trim().split(/\s+/).length)
  return f.feConvolveMatrix({
    in: source,
    order: `${rank},${rank}`,
    divisor: 1,
    kernelMatrix: matrix,
  })
}

export const blur = (stdDeviation) => (source) =>
  f.feGaussianBlur({
    stdDeviation: stdDeviation,
    in: source,
  })

// Color Effects
export const saturate = (amount) => (source) =>
  f.feColorMatrix({
    type: "saturate",
    in: source,
    values: amount,
  })

export const hueRotate = (amount) => (source) =>
  f.feColorMatrix({
    type: "hueRotate",
    values: amount,
    in: source,
  })

export const offsetColor = (amount) => (source) =>
  f.feColorMatrix({
    type: "matrix",
    values: `1 0 0 0 ${amount}
             0 1 0 0 ${amount}
             0 0 1 0 ${amount}
             0 0 0 1 0`,
    in: source,
  })

export const invert = (source) =>
  f.feColorMatrix({
    type: "matrix",
    values: `-1  0  0  0  1
              0 -1  0  0  1
              0  0 -1  0  1
              0  0  0  1  0`,
    in: source,
  })

export const colorTint = (color, amount) => (source) =>
  Filter.do(function* () {
    const tint = yield fillColor(color)
    return crossFade(tint, source, amount)
  })

// Morphology Effects
export const shrink = (amount) => (source) =>
  f.feMorphology({
    operator: "erode",
    radius: amount,
    in: source,
  })

export const thicken = (amount) => (source) =>
  f.feMorphology({
    operator: "dilate",
    radius: amount,
    in: source,
  })

export const outline = (thickness) => (source) =>
  Filter.do(function* () {
    const thickened = yield thicken(thickness)(source)
    return f.feComposite({
      operator: "out",
      in: thickened,
      in2: source,
    })
  })

// Flat Color Effects
export const fillColor = (color) =>
  tiledImage(solidColor(color), {
    width: 100,
    height: 100,
  })

export const colorize = (color) => (source) =>
  Filter.do(function* () {
    const fill = yield fillColor(color)
    return f.feComposite({
      operator: "in",
      in: fill,
      in2: source,
    })
  })

// Image Effects
export const tile = (source) =>
  f.feTile({
    in: source,
  })

export const image = (imageSource, dimensions = {}) =>
  f.feImage({
    "xlink:href": imageSource,
    ...dimensions,
  })

export const tiledImage = (imageSource, dimensions = {}) =>
  Filter.do(function* () {
    const patch = yield image(imageSource, dimensions)
    return tile(patch)
  })

// Offset Effects
export const offset = (dx, dy) => (source) =>
  f.feOffset({
    in: source,
    dx: dx,
    dy: dy,
  })

export const displace = (map, options = {}) => (source) =>
  f.feDisplacementMap({
    in: source,
    in2: map,
    ...options,
  })

// Combinations
export const crossFade = (img1, img2, amt) =>
  Filter.do(function* () {
    const attenuated = yield f.feColorMatrix({
      in: img1,
      type: "matrix",
      values: `1 0 0  0     0
               0 1 0  0     0
               0 0 1  0     0
               0 0 0 ${amt} 0`,
    })
    const blend = yield f.feBlend({
      in: attenuated,
      in2: img2,
      mode: "normal",
    })

    return Filter.done(blend)
  })
