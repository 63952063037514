import { Filter } from "@mattsnax/filter-effects"
import * as f from "@mattsnax/filter-effects"

// TODO: extract automatically from gif??
import frame1 from "./glitter-1.png"
import frame2 from "./glitter-2.png"
import frame3 from "./glitter-3.png"

import * as e from "../effects"

const glitterFill = (frame) => (source) =>
  Filter.do(function* () {
    const background = yield e.tiledImage(frame, {
      width: 60,
      height: 30,
    })
    const effect = yield f.feComposite({
      in: background,
      in2: source,
      operator: "in",
    })
    return Filter.done(effect)
  })

const glitterFilter = (glitterFrame) => (source) =>
  Filter.do(function* () {
    const outline = yield e.outline(1)(source)
    const coloredOutline = yield e.colorize("#94d82d")(outline)
    const glitter = yield glitterFill(glitterFrame)(source)
    const effect = yield f.feBlend({
      in: coloredOutline,
      in2: glitter,
    })
    return Filter.done(effect)
  })

const makeFilter = (frame) =>
  Filter(f.sourceGraphic().flatMap(glitterFilter(frame)), {
    id: "glitter",
    x: "-20%",
    y: "-20%",
    width: "140%",
    height: "140%",
    filterUnits: "objectBoundingBox",
    primitiveUnits: "userSpaceOnUse",
    "color-interpolation-filters": "sRGB",
  })

const frames = [frame1, frame2, frame3]
const filters = frames.map(makeFilter)

export default filters
