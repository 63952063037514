export const toBase64 = async (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })

export const loadFile = async (filename) => {
  const resp = await fetch(filename)
  const blob = await resp.blob()
  const data = await toBase64(blob)
  return data
}

export const downloadFile = (url, filename) => {
  const link = document.createElement("a")
  link.download = filename
  link.style.opacity = "0"
  link.href = url
  link.click()
  link.remove()
}
