import { svgSrcToURL } from "./svg"

export const solidColor = (color) =>
  svgSrcToURL(`\
    <svg width="100" height="100"
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <rect fill="${color}" width="100" height="100"/>
    </svg>`)
