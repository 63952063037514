import filters from "./filter"
import oswaldBase64 from "./oswald-base64" // TODO: load from source with webpack?

const glitterTextFrame = (filter) => (text, { width, height }) => `\
  <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
    <defs xmlns="http://www.w3.org/1999/xhtml">
      ${new XMLSerializer().serializeToString(filter.build())}
    </defs>
    <foreignObject width="100%" height="100%" >
      <style>
        @font-face {
          font-family: 'Oswald';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${oswaldBase64}) format('woff');
        }
      </style>
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style="font-size: 46px; font-family: Oswald, Impact, sans-serif; filter: url(&quot;#glitter&quot;); padding: 12px 18px; display: inline-block;"
      >
        ${text}
      </div>
    </foreignObject>
  </svg>
`

export const glitterTextSvgs = (text, { width, height }) =>
  filters.map(glitterTextFrame).map((f) => f(text, { width, height }))
