import * as React from "react"
import { useState, useRef, forwardRef } from "react"
import { snapshotSvgSrcAsPng } from "../utils/svg"
import { imgsToGif } from "../utils/image"
import { downloadFile } from "../utils/file"
import { glitterTextSvgs } from "../utils/glitter-text/svg"
import { Body } from "../components/body"
import { Button } from "../components/button"
import GlitterTextFilter from "./glitter-text.inline.svg"

import "typeface-roboto"
import "typeface-oswald"
import "../styles/document.scss"

import styles from "./glitter-text.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

const makeGif = async (text, { width, height }) => {
  const svgFrames = glitterTextSvgs(text, { width, height })
  const pngs = await Promise.all(
    svgFrames.map((svgSrc) => snapshotSvgSrcAsPng(svgSrc, { width, height }))
  )
  const gif = await imgsToGif(pngs, { width, height })
  return gif
}

const Preview = forwardRef(({ children }, ref) => {
  return (
    <div className={cx("preview")}>
      <div className={cx("preview_bounds")}>
        {children}
        <svg className={cx("preview_svg")} ref={ref}>
          <GlitterTextFilter />
          <foreignObject width="100%" height="100%">
            <div className={cx("preview_text")}>{children}</div>
          </foreignObject>
        </svg>
      </div>
    </div>
  )
})

const GlitterText = () => {
  const [text, setText] = useState("Glitter Text")
  const svgRef = useRef()

  const onChange = (e) => {
    setText(e.target.value)
  }

  const saveImage = async () => {
    const svg = svgRef.current
    const { width, height } = svg.getBoundingClientRect()
    const gif = await makeGif(text, { width, height })
    downloadFile(gif, "glitter.gif")
  }

  return (
    <Body>
      <h1>Glitter Text Generator</h1>
      <br></br>
      <br></br>
      <div className={cx("form")}>
        <div className={cx("form-row")}>
          <div className={cx("text-field")}>
            <label className={cx("text-field_label")} htmlFor="text">
              Text
            </label>
            <input
              className={cx("text-field_input")}
              id="text"
              value={text}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={cx("form-row")}>
          <Preview ref={svgRef}>{text}</Preview>
        </div>
        <div className={cx("form-row")}>
          <Button color="blue" onClick={saveImage}>
            Save Image
          </Button>
        </div>
      </div>
    </Body>
  )
}

export default GlitterText
