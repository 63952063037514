import btoa from "btoa"
import computedStyleToInlineStyle from "computed-style-to-inline-style"
import { loadImage, imgToURL } from "./image"

export const inlineStyles = (el) => {
  computedStyleToInlineStyle(el, {
    recursive: true,
    properties: ["font-size", "font-family", "filter", "padding", "display"],
  })
  return el
}

export const svgToURL = (svg) => {
  const svgSrc = new XMLSerializer().serializeToString(svg)
  const svgURL = `data:image/svg+xml;base64,${btoa(svgSrc)}`
  return svgURL
}

export const svgSrcToURL = (svgSrc) =>
  `data:image/svg+xml;base64,${btoa(svgSrc)}`

export const snapshotSvgSrcAsPng = async (svgSrc, { width, height }) => {
  const svgURL = svgSrcToURL(svgSrc)
  const img = await loadImage(svgURL)
  const pngURL = imgToURL(img, { width, height })
  return pngURL
}

export const snapshotSvgAsPng = async (
  svg,
  { width, height } = svg.getBBox()
) => {
  const inlinedSvg = inlineStyles(svg)
  const svgURL = svgToURL(inlinedSvg)
  const img = await loadImage(svgURL)
  const pngURL = imgToURL(img, { width, height })
  return pngURL
}
